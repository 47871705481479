<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Producto - Nuevo</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>

                <b-col md="3">
                  <div class="w-100 text-center">
                     <img class="img-fluid" :src="previewImage">
                  </div>
                  <b-col md="12">
                    <b-form-group label="Foto: ">
                      <b-form-file @change="PreviewImage" accept="image/*" v-model="photo" placeholder="Seleccione un foto..." drop-placeholder="Suelta la imagen aquí..."></b-form-file>
                      <small class="form-text text-center text-secondary">Tamaño recomendado 400px x 500px</small>
                    </b-form-group>
                  </b-col>
                </b-col>

                <b-col md="9">
                  <b-row>
                      <b-col md="3">
                        <b-form-group label="Categoria :">
                          <b-form-select v-model="product.id_category_product" :options="categories"></b-form-select>
                          <small v-if="errors.id_category_product" class="form-text text-danger">Seleccione una categoria</small>
                        </b-form-group>
                      </b-col>

                      <b-col md="3">
                        <b-form-group label="Codigo:">
                          <b-form-input readonly type="text" class="text-center" v-model="product.code"></b-form-input>
                          <small v-if="errors.code" class="form-text text-danger" >Ingrese un código</small>
                        </b-form-group>
                      </b-col>

                      <b-col md="6">
                        <b-form-group label="Nombre:">
                          <b-form-input  type="text" v-model="product.name" ></b-form-input>
                          <small v-if="errors.name" class="form-text text-danger">Ingrese un nombre</small>
                        </b-form-group>
                      </b-col>

                      <b-col md="3">
                        <b-form-group label="Presentación :">
                          <b-form-input  type="text" ref="presentation" v-model="product.presentation" ></b-form-input>
                          <small v-if="errors.presentation" class="form-text text-danger">Ingrese una presentacion</small>
                        </b-form-group>
                      </b-col>

                      <b-col md="3">
                        <b-form-group label="Unidad de Medida :">
                          <b-form-select v-model="product.unit_measure" :options="unit_measure"></b-form-select>
                        </b-form-group>
                      </b-col>

                      <b-col md="6">
                        <b-form-group label="Descripción:">
                          <b-form-input ref="description"  v-model="product.description"></b-form-input>
                        </b-form-group>
                      </b-col>


                      <b-col md="3">
                        <b-form-group label="Codigo de Barras:">
                          <b-form-input type="text" v-model="product.barcode"></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col md="3">
                        <b-form-group label="Tipo de Afectación:">
                          <b-form-select v-model="product.igv" :options="igv"></b-form-select>
                        </b-form-group>
                      </b-col>

                      <b-col md="3">
                        <b-form-group label="Producto Interno :">
                          <b-form-select type="text" v-model="product.internal_product" :options="internal_product"></b-form-select>
                        </b-form-group>
                      </b-col>

                      <b-col md="3">
                        <b-form-group label="Control de Stock? :">
                          <b-form-select v-model="product.stock" :options="stock"></b-form-select>
                        </b-form-group>
                      </b-col>

                      <b-col md="9">
                        <b-form-group label="Proveedor :">
                        <v-select placeholder="Seleccione un proveedor" class="w-100" :filterable="false" label="name" v-model="mprovider" @search="SearchProvider" :options="providers"></v-select>
                        <small v-if="errors.id_provider" class="form-text text-danger">Seleccione un proveedor</small>
                        </b-form-group>
                      </b-col>

                      <b-col md="3">
                        <b-form-group label="Estado :">
                          <b-form-select v-model="product.state" :options="state"></b-form-select>
                        </b-form-group>
                      </b-col>

                      <b-col md="5"></b-col>
                      <b-col md="2">
                        <b-button type="submit" class="form-control"  variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button >
                      </b-col>


                  </b-row> 
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import LoadingComponent from './../pages/Loading'

export default {
  name: "UsuarioAdd",
  components:{
      vSelect,
      Keypress: () => import('vue-keypress'),
    LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: "Product",
      role: 2,
      product: {
        id_category_product: "",
        id_provider: "",
        code: "",
        name: "",
        barcode: "",
        description: "",
        presentation: "",
        existence_type: "01",
        unit_measure: "NIU",
        igv: "10",
        internal_product: 0,
        photo: "",
        unspsc_code:'23251602',
        supplies: 0,
        minimal_price: 0.0,
        sale_price: 0.0,
        stock: 1,
        state: 1,
      },
      mprovider :{id:1, name:'Proveedor varios'},
      providers: [],
      previewImage: 'https://loisjeans.id/skin/frontend/base/default/images/catalog/product/placeholder/image.jpg',
      categories: [],
      photo: null,
      state:[
        {value:1,text:'Activo'},
        {value:0,text:'Inactivo'},
      ],
      supplies:[
        {value:1,text:'SI'},
        {value:0,text:'NO'},
      ],
      igv:[
        {value:10,text:'Gravado'},
        {value:20,text:'Exonerado'},
        {value:30,text:'Inafecto'},
      ],
      unit_measure:[
        {value:'4A',text:'BOBINAS'},
        {value:'BJ',text:'BALDE'},
        {value:'BLL',text:'BARRILES'},
        {value:'BG',text:'BOLSA'},
        {value:'BO',text:'BOTELLAS'},
        {value:'BX',text:'CAJA'},
        {value:'CMK',text:'CENTIMETRO CUADRADO'},
        {value:'CMQ',text:'CENTIMETRO CUBICO'},
        {value:'CMT',text:'CENTIMETRO LINEAL'},
        {value:'CEN',text:'CIENTO DE UNIDADES'},
        {value:'CY',text:'CILINDRO'},
        {value:'DZN',text:'DOCENA'},
        {value:'DZP',text:'DOCENA POR 10**6'},
        {value:'BE',text:'FARDO'},
        {value:'GLI',text:'GALON INGLES (4,545956L)'},
        {value:'GRM',text:'GRAMO'},
        {value:'KGM',text:'KILOGRAMO'},
        {value:'CA',text:'LATAS'},
        {value:'LBR',text:'LIBRAS'},
        {value:'LTR',text:'LITRO'},
        {value:'MTR',text:'METRO'},
        {value:'MGM',text:'MILIGRAMOS'},
        {value:'MIL',text:'MILLARES'},
        {value:'UM',text:'MILLON DE UNIDADES'},
        {value:'ONZ',text:'ONZAS'},
        {value:'PF',text:'PALETAS'},
        {value:'PK',text:'PAQUETE'},
        {value:'GRM',text:'GRAMO'},
        {value:'PR',text:'PAR'},
        {value:'C62',text:'PIEZAS'},
        {value:'NIU',text:'UNIDAD (BIENES) '},
        {value:'ZZ',text:'UNIDAD (SERVICIOS)'},
        {value:'GRM',text:'US GALON (3,7843 L)'},
        {value:'GLL',text:'GRAMO'},
      ],
      stock :[
        {value:0,text:'NO'},
        {value:1,text:'SI'},
      ],                                               
      internal_product :[
        {value:0,text:'NO'},
        {value:1,text:'SI'},
      ],
      errors: {
        id_category_product: false,
        code: false,
        name: false,
        state: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ListCategories();
    this.GetCorrelative();
  },
  methods: {
    PreviewImage,
    ListCategories,
    GetCorrelative,
    AddProduct,
    Validate,
    SearchProvider,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function PreviewImage(event) {
  var input = event.target;
  if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = (e) => {
          this.previewImage = e.target.result;
      }
      reader.readAsDataURL(input.files[0]);
  }
  this.product.photo = input.files[0];
}

function SearchProvider(search, loading) {
  
    let me = this;
    let url = this.url_base + "search-providers/" + search;
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
      }).then(function (response) {
            me.providers = response.data.result;
            loading(false);
      })
    }
    
}

function GetCorrelative() {
  let me = this;
  let url = this.url_base + "get-correlative/Product";
  axios({
    method: "GET",
    url: url,
    headers: {token: this.token,module: this.module,role: this.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.product.code = response.data.result.number;
    } 
  })
}

function ListCategories() {
  let me = this;
  let url = this.url_base + "category-product/list-active";
  axios({
    method: "GET",
    url: url,
     headers: { token: this.token, module: this.module, role: this.role},
  })
  .then(function (response) {
    me.categories = [{value:'',text:'Seleccione una categoria'}];
    if (response.data.status == 200) {
      for (let index = 0; index < response.data.result.length; index++) {
        const element = response.data.result[index];
          me.categories.push({value:element.id_category_product,text:element.name});
      }
    } else {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    }
  })
  .catch((error) => {
    Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
  });
}

function AddProduct() {

  let me = this;
  let url = me.url_base + "product/add";
  let data = new FormData();
  data.append("id_category_product", this.product.id_category_product);
  data.append("id_provider", me.mprovider.id);
  data.append("code", this.product.code);
  data.append("name", this.product.name);
  data.append("barcode", this.product.barcode);
  data.append("description", this.product.description);
  data.append("presentation", this.product.presentation);
  data.append("existence_type", this.product.existence_type);
  data.append("unit_measure", this.product.unit_measure);
  data.append("igv", this.product.igv);
  data.append("internal_product", this.product.internal_product);
  data.append("photo", this.product.photo);
  data.append("unspsc_code", this.product.unspsc_code);
  data.append("minimal_price", this.product.minimal_price);
  data.append("sale_price", this.product.sale_price);
  data.append("supplies", this.product.supplies);
  data.append("stock", this.product.stock);
  data.append("state", this.product.state);

  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.product.id_category_product = "",
        me.product.id_provider = "",
        me.product.code = "",
        me.product.name = "",
        me.product.barcode = "",
        me.product.description = "",
        me.product.presentation = "",
        me.product.existence_type = "01",
        me.product.unit_measure = "NIU",
        me.product.igv = "10",
        me.product.internal_product = 0,
        me.product.photo = "",
        me.product.unspsc_code ='23251602',
        me.product.supplies = 0,
        me.product.minimal_price = 0.0,
        me.product.sale_price = 0.0,
        me.product.stock = 1,
        me.product.state = 1,
        me.previewImage = 'https://loisjeans.id/skin/frontend/base/default/images/catalog/product/placeholder/image.jpg';
        me.GetCorrelative();
        me.photo = null;
        Swal.fire({ icon: 'success', text: response.data.message , timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

function Validate() {

  this.errors.id_category_product = this.product.id_category_product.length == 0 ? true : false;
  this.errors.id_provider = this.mprovider == null ? true : false;
  this.errors.name = this.product.name.length == 0 ? true : false;
  this.errors.code = this.product.code.length == 0 ? true : false;
  this.errors.minimal_price = this.product.minimal_price.length == 0 ? true : false;
  this.errors.sale_price = this.product.sale_price.length == 0 ? true : false;
  this.errors.state = this.product.state.length == 0 ? true : false;

  if (this.errors.id_category_product) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.id_provider) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.code) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.name) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.minimal_price) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.sale_price) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.state) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }

  let me = this;

  if (!this.validate) {
    Swal.fire({
      title: "Esta seguro de registrar el producto ?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Estoy de acuerdo!",
    }).then((result) => {
      if (result.value) {
        this.AddProduct();
      }
    });

  }

}
</script>
